@import "../../styles/_colors.scss";

.divFlex .content.portfolio {
    padding: 0 5px;

    @media all and (max-width: 768px) {
        margin: 0 10px;
    }
}

.divFlex .content.portfolio>div {
    padding-bottom: 70px;
}

/* .divFlex .content.portfolio .title-item {
    width: 100%;
}
 */
.divFlex .content.portfolio .title-item a {
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;


    transition: 0.3s;
}


.divFlex .content.portfolio .title-item a {
    transition: 0.3s;
    cursor: pointer;
    position: relative;

    @media all and (max-width: 768px) {
        font-size: 13px;
    }

    // Aplica os estilos quando o item está em hover ou ativo
    &:hover,
    &.active {

        // Estilos para o elemento antes do item de menu
        &::before {
            height: 2px;
            visibility: visible;
            transform: scaleX(1);
        }
    }

    // Estilos iniciais para o elemento antes do item de menu
    &::before {
        content: "";
        position: absolute;
        width: 90%;
        transform-origin: left; // Define a origem da transformação para a esquerda
        height: 2px;
        bottom: 12px;
        left: 0px;
        background-color: #ffffff;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;

        @media all and (max-width: 768px) {
            bottom: 1px;
            width: 86%;
        }

    }
}


.divFlex .content.portfolio .title-item a img {
    height: 17px;

    @media all and (max-width: 768px) {
        height: 12px;

    }
}

.divFlex .content.portfolio .title-item a h1 {
    font-size: 24px;
    font-weight: bold;

    letter-spacing: 10%;
    line-height: 65px;

    @media all and (max-width: 768px) {
        line-height: 35px;
        font-size: 15px;

    }

}

.divFlex .content.portfolio p {
    font-size: 15px;
    color: #FFFFFF99;
    letter-spacing: 15%;
    line-height: 31px;

    width: 90%;
    padding: 0;
}

.divFlex .content.portfolio div.container-skills-portfolio {
    display: flex;
    flex-wrap: wrap;
}

.divFlex .content.portfolio span.btn-skills-portfolio {
    background-color: $background-btn;
    font-weight: bold;
    letter-spacing: 15%;
    line-height: 31px;
    border-radius: 15px;
    padding: 3px 23px;
    margin: 25px 15px 0 0;

    @media all and (max-width: 768px) {
        padding: 3px 18px;
        font-size: 15px;
    }
}