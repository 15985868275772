@import "../../styles/_colors.scss";

.divFlex .content.experience div {
    padding-bottom: 50px;

    @media all and (max-width: 768px) {
        margin: 0 5px;
    }
}

.divFlex .content.experience h2 {
    font-size: 15px;
    color: $color-text;
    letter-spacing: 15%;
    line-height: 31px;
}

.divFlex .content.experience h1 {
    font-size: 24px;
    font-weight: bold;

    letter-spacing: 10%;
    line-height: 65px;

    @media all and (max-width: 768px) {
        line-height: 35px;
        font-size: 20px;

    }
}

.divFlex .content.experience ul {
    list-style: circle !important;
}

.divFlex .content.experience ul li::before {
    content: "→ ";
}

.divFlex .content.experience ul li {
    font-size: 15px;
    color: $color-text;
    padding: 10px 0;
    @media all and (max-width: 768px) {
        font-size: 13px;
    }
}

.divFlex .content.experience div.container-skills-experience {
    display: flex;
    flex-wrap: wrap;
}

.divFlex .content.experience span.btn-skills-experience {
    background-color: $background-btn;
    font-weight: bold;
    letter-spacing: 15%;
    line-height: 31px;
    border-radius: 15px;
    padding: 3px 23px;
    margin: 12px 15px 0 0;

    @media all and (max-width: 768px) {
        padding: 3px 18px;
        font-size: 15px;
    }
}