@import "../../styles/_colors.scss";

.divFlex .myInfos {

  opacity: 1;
  transition: 0.3s;
  
  @media all and (max-width: 768px) {
    margin: 0 15px;

    position: static;
    left: 0px;

  }
}

.divFlex .myInfos.hidden {
  position: fixed;
  left: -100px;
  opacity: 0;
}


.divFlex .btn-responsive {
  position: absolute;
  top: 0px;
  right: 30px;
  display: none;
  height: 100px;

  transition: 0.3s;

  @media all and (max-width: 768px) {
    display: block;
  }
}

.divFlex .btn-responsive.active {
  rotate: 180deg;
}



.divFlex .myName {
  font-size: 57px;
  font-weight: bold;

  letter-spacing: 10%;
  line-height: 65px;

  @media all and (max-width: 768px) {
    font-size: 30px;
  }

}

.divFlex .title {
  font-size: 24px;
  font-weight: bold;

  letter-spacing: 10%;
  line-height: 65px;

  @media all and (max-width: 768px) {
    font-size: 18px;
  }

}

.divFlex .sentence {
  font-size: 20px;
  font-weight: bold;
  color: $color-text;

  letter-spacing: 10%;
  line-height: 31px;

  @media all and (max-width: 768px) {
    font-size: 13px;
  }

}


.divFlex .menu>ul {
  list-style: none;
  padding-top: 10px;
}

.divFlex .menu>ul li {
  width: 100px;
  font-weight: bold;
  padding: 10px 0;
  letter-spacing: 10%;
  line-height: 31px;
  opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
  position: relative;

  @media all and (max-width: 768px) {
    font-size: 13px;
  }

  // Aplica os estilos quando o item está em hover ou ativo
  &:hover,
  &.active {
    opacity: 1;
    padding-left: 50px;

    // Estilos para o elemento antes do item de menu
    &::before {
      width: 30%;
      height: 2px;
      visibility: visible;
      transform: scaleX(1);

    }
  }

  // Estilos iniciais para o elemento antes do item de menu
  &::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 47%;
    left: 0;
    background-color: #ffffff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;

    @media all and (max-width: 768px) {
      width: 30%;
    }

  }
}

.divFlex .icons {
  width: 60%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  z-index: 2;

}

.divFlex .icons a img {
  opacity: 0.6;
  height: 40px;
  transition: 0.3s;

  @media all and (max-width: 768px) {
    height: 30px;
  }


}

.divFlex .icons a img:hover {
  opacity: 1;
}