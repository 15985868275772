@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  margin: 0;
  padding: 0;
}


.App::-webkit-scrollbar {
  width: 2px;               /* width of the entire scrollbar */
}

.App::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.App::-webkit-scrollbar-thumb {
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #FFFFFF99;  /* creates padding around scroll thumb */
}

.App {
  width: 100vw;
  height: 100vh;

  font-family: "Montserrat", sans-serif;

  /* fundo */
  background: url("../src/img/home 2.svg") fixed no-repeat;
  background-size: cover;
  overflow-x: hidden;
  z-index: -1;

  @media all and (max-width: 768px) {
    overflow: hidden;
  }
  
}


.container {
  margin: auto;
  padding: 0 10px;
  color: azure;
  width: 1170px;
  height: 100vh;

  @media all and (max-width: 768px) {
    padding: 0px;
  }

}

.pointers {
  position: static;
  width: 40%;
  bottom: 100px;
  left: 0px;
  z-index: 0;
  
  @media all and (max-width: 768px) {
    display: none;
  }

}

.divFlex {
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.divFlex .content {
  @media all and (max-width: 768px) {
      height: 100vh;
      padding: 0 7px;

  }
}