
.divFlex .content {
    max-width: 552px;
    max-height: 754px;
    height: 400px;
    overflow-y: auto;    
    
  @media all and (max-width: 768px) {
    max-width: 90vw !important;
    max-height: 100vh !important;
    height: 80vh !important;

    margin: 0 auto;
  }

}


.divFlex .content::-webkit-scrollbar {
  width: 1px;               /* width of the entire scrollbar */
}

.divFlex .content::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.divFlex .content::-webkit-scrollbar-thumb {
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #FFFFFF99;  /* creates padding around scroll thumb */
}
/* rgb(44,1,77) */
.divFlex .content p {
    font-size: 20px;
    color: #FFFFFF99;
    letter-spacing: 10%;
    line-height: 31px;
    padding: 7px;

    @media all and (max-width: 768px) {
      font-size: 15px;

    }

}
